// Stylesheets
import './assets/scss/site.scss';

// Modules
// import {fixSvgIntrinsicSizing} from './assets/modules/fixSvgIntrinsicSizing';
// import {hover} from './assets/modules/hover';''
// import {lazyload} from './assets/modules/lazyload';


// fixSvgIntrinsicSizing();
// hover();
// lazyload();